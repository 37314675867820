.summary-buttons {
  border-radius: 10px;
  border: 1px solid #dee6eb;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  h1 {
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #9e9e9e;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
  &__child {
    width: 100%;
    border-right: 1px solid #dee6eb !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .MuiButton-label {
      display: flex;
      flex-direction: column;
    }
    &:last-child {
      border: none !important;
    }
  }

  &__actions {
    position: absolute;
    top: 20px;
    right: 25px;
  }
}
