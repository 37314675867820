.calendly {
  min-height: 100vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  iframe{
    min-height: 99vh;
    width: 100vw;
  }
}

