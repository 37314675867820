@import '../abstracts/mixins';

.privacy {
  padding: 2%;
  @include all-mobile-devices{
    padding-left: 0;
    width: 100vw !important;
    max-width: 95%!important;
    padding-right: 16px !important;
  }
  h1 {
    font-size: 30px;
    margin-top: 15px;
  }
  p{
    margin-top: 10px;
  }
  &__title {
    font-size: 36px;
    margin-top: 0;
  }
  &__header {
    padding: 30px 0;
  }
  .bold{
    font-weight: bold;
  }
  li{
    margin-left: 25px;
    margin-top: 5px;
  }
  img{
    max-width: 200px;
  }
  table {
    margin-top: 15px;
    th{
      border: 1px solid black;
      padding: 5px;
    }
    td{
      border: 1px solid black;
      padding: 5px;
    }
  }
}
