.line-chart {
  max-height: 400px;
  border-radius: 10px;
  border: 1px solid #dee6eb;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;

  .VictoryContainer {
    max-height: 100%;
  }
}
