.login-history {
  &__table {
    width: 100%;
    margin-bottom: 20px;
    &__header {
      background: #3a3e4a;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: white;
      padding: 10px 20px;
      p {
        width: 50%;
      }
    }

    &__body {
      background: #f3f7fa;
      border-radius: 5px;

      &__row {
        display: flex;
        flex-direction: row;
        padding: 5px 20px;
        p {
          width: 50%;
        }
      }
    }
  }
}
