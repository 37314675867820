.logins {
  border-radius: 10px;
  border: 1px solid $border-grey;
  //min-height: 300px;
  background-color: $white;
  //margin-top: 25px;

  &__header {
    padding: 25px;
    height: 60px;
  }

  &__category {
    color: $red;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
  }
}

.logins-list {
  height: 230px;
  overflow-y: scroll;

  &__row {
    padding: 5px 0px;
    border-bottom: 1px solid $border-grey;
  }

  &__row--last {
    border-bottom: unset;
  }

  &__location {
    padding-left: 25px;
    p{
      max-width: 95%;
      word-break: break-word;
    }
    .MuiIconButton-root{
      padding: unset;
      color: unset;
      font-family: 'PT Sans', sans-serif;
      text-align: left;
    }
    .MuiIconButton-root:hover{
      background: unset;
    }

    span{
      width: 100%;
      .MuiButtonBase-root{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  &__count {
    padding-right: 25px;

    .MuiIconButton-root{
      padding: unset;
      color: unset;
      font-family: 'PT Sans', sans-serif;
    }
    .MuiIconButton-root:hover{
      background: unset;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
}
