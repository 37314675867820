.url {
    height: 100%;
    width: 100%;
    background: black;

    &__iframes-wrap{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__iframes{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    &__iframe-container{
        max-height: 80vh;
        padding-right: 20rem;
        padding-left: 20rem;
        padding-top: 5rem;
        margin-right: auto;
        margin-left: auto;
        // overflow-x: hidden;
    }

    &__iframe-wrapper {
        padding: 56.25% 0 0 0;
        position: relative;
        display: block;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-top-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 0px;
            border-left-width: 0px;
        }
    }

    &__button-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5%;

    }
    &__button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}


@media (min-width: 1800px){
    .url {

        &__iframe-container {
            // max-height: 60vh;
            padding-right: 30rem;
            padding-left: 30rem;
        }

        &__button-wrapper {
            margin-top: 5%;
        }
    }
}

@media (min-width: 2200px){
    .url {

        &__iframe-container {
            padding-right: 30rem;
            padding-left: 30rem;
        }

        &__button-wrapper {
            margin-top: 5%;
        }
    }
}
@media (min-width: 2500px){
    .url {

        &__iframe-container {
            padding-right: 55rem;
            padding-left: 55rem;
        }

        &__button-wrapper {
            margin-top: 2%;
        }
    }
}
