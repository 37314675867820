.password-requirements {
  &-list {
    list-style: none;
  }

  &__requirement {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }


  &__icon-satisfied {
    color: $green-dark;
    margin-right: 10px;
  }

  &__icon-unsatisfied {
    color: $red;
    margin-right: 10px;
  }
}
