@import '../../abstracts/mixins';
.project-define-roles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-roles-buttons {
  line-height: 12px;
  //height: 100px;
  margin-bottom: 30px;

  &__card {
    background-color: $white !important;
    color: unset !important;
    padding: 5px 15px;
    cursor: pointer;
    min-width: 150px;
    height: 48px;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    &--active {
      background-color: $red !important;
      color: $white !important;
      padding: 5px 15px;
    }

    .add-title {
      align-items: center;
      p {
        margin-left: 10px;
      }
    }
  }

  &__card-title {
    line-height: 25px;
  }

  &__card-name {
    text-align: center;
  }
}

.permissions-crud--title {
  margin-bottom: 15px !important;
  .text-field {
    width: 100%;
  }
  p {
    padding-left: 30px;
  }
}
.permissions-crud {
  background: #ffffff;
  border: 1px solid #9e9e9e;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 30px;

  @include tablet-ipad {
    padding: 10px;
    .MuiGrid-root {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }
  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }

  &__header {
    font-weight: bold;
    margin-bottom: 30px !important;
  }
}

.define-roles-footer {
  margin-top: 50px !important;
}
