.MuiButton-root.btn {
  /*Anything here will apply to all buttons*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  height: 50px;
  border-radius: 50px !important;
  border: none;

  background: $red;

  /* Drop */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  /* Font */
  font-size: 19px !important;
  font-weight: 700;
  line-height: 18px;
  color: #ffffff !important;
  text-transform: uppercase;
   &:hover {
     /*Anything here will apply to all buttons when you mouse over them*/
     //transform: scale(1.1, 1.1);
     //transition: all 0.5s;
     background: rgb(160, 26, 16);
   }

  &.btn-lrg {
    /*Anything here will apply to the large buttons*/

    /* Font */
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    min-width: 250px !important;
  }

  &.btn-alt {
    /*Anything here will apply to the alternate buttons*/
    background: #2480ed;

    &:hover {
      /*Anything here will apply to the alternate buttons when you mouse over them*/
      background: rgb(20, 59, 107);
    }
  }

  &.Mui-disabled {
    background: rgba($grey, 0.4);
  }
}

.circle-btn {
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  border-radius: 17.5px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  line-height: 0px;

  &--white {
    background-color: $white;
  }
}

.view-more {
  &__button {
    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .MuiSvgIcon-root {
      margin-right: 10px;
    }
  }
}

.blue-btn {
  background: #2480ed !important;
  margin-top: 10px !important;
  .MuiButton-label {
    font-size: 15px;
  }
}

.btn-cancel {
  max-width: 110px !important;
  width: 110px !important;
  margin-right: 10px !important;
  height: 45px;
  background: gray !important;
  .MuiButton-label {
    font-size: 15px;
  }
}

.btn-save {
  max-width: 110px !important;
  width: 110px !important;
  margin-right: 10px !important;
  height: 45px;
  .MuiButton-label {
    font-size: 15px;
  }
}
.btn-confirm {
  max-width: 110px !important;
  width: 110px !important;
  margin-right: 10px !important;
  height: 45px;
  .MuiButton-label {
    font-size: 15px;
  }
}

.btn-transparent {
  background: transparent !important;
  color: black !important;
  box-shadow: unset;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .MuiButton-text {
  }
}

.btn-medium {
  min-width: 100px !important;
}

.btn-square-white {
  background: white !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  max-width: 50px !important;
  min-width: 50px !important;
  .MuiButton-label {
    color: black !important;
    .MuiSvgIcon-root {
      font-size: 30px !important;
    }
  }
}
