// Generic component recolouring and sidebar repositioning goes here

.zoom-auditorium {
  // Root element of the zoom client
  #zmmtg-root {
    background: none !important;
    position: absolute;

    //-- The container on the "Join Meetings" screen
    .main-content {
      background: none !important;
      position: absolute;
      top: 0;
      left: 50%;
      height: 0 !important;
      width: 0 !important;

      .media-preview-container {
        margin: 0;

        .meeting-title {
          display: none;
        }

        .av-preview-container {
          top: 0;
        }
      }
    }

    .meeting-app {
      width: 100% !important;
      height: 100% !important;

      //-- The container on the Meeting screen
      .meeting-client {
        background: none !important;
        position: absolute;

        .meeting-client-inner {
          position: absolute;
        }

        // Position screen relative to top-center
        #wc-container-left {
          position: absolute;
          top: 0;
          left: 50%;
        }
      }
    }
  }

  //-- Main body of the zoom call video area
  .video-share-layout {
    background: transparent !important;
    position: absolute;
  }

  //-- Footer/sidebar that contains controls
  .footer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 150px;
    background: white;

    width: 100px !important;
    height: 100% !important;

    .footer__inner {
      // Align footer vertically
      flex-direction: column;

      & > div {
        // Align footer vertically
        flex-direction: column;

        // Make all buttons full width
        .join-audio-container__btn,
        .send-video-container__btn,
        .footer-button-base__button,
        .footer-button__button {
          width: 100%;
          max-width: 100%;
          border-radius: 0;

          svg path {
            fill: black;
            fill-opacity: 0.62;
          }
        }
        .footer-button__wrapper,
        .sharing-entry-button-container,
        .more-button,
        .more-button .btn-group {
          display: flex;
          flex-flow: row nowrap;
          justify-content: stretch;
          flex: 1 1 100%;

          svg path {
            fill: black;
            fill-opacity: 0.62;
          }
        }

        &.footer__btns-container {
          & > div:not([class]),
          & > div:not([class]) > div:not([class]) {
            // two buttons have empty unstyled divs wrapping them
            display: flex;
            flex-flow: row nowrap;
            justify-content: stretch;
            flex: 1 1 100%;
          }
        }

        // Restore radius on sub-button
        .footer-sub-toggle-base {
          border-radius: 6px;
        }

        // Recolour text and icons
        .join-audio-container__btn,
        .send-video-container__btn,
        .footer-button-base__button,
        .footer-button__number-counter {
          color: black !important;

          svg path {
            fill: black;
            fill-opacity: 0.62;
          }

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  //-- Layout when screen not being shared
  .main-layout {
    .single-main-container__main-view {
      background: #000000 !important;
    }
  }

  .sharing-layout {
    height: 100% !important;
    width: 100% !important;

    .sharee-container {
      height: 100% !important;
      width: 100% !important;

      .sharee-container__viewport {
        height: 100% !important;
        width: 100% !important;
        margin-top: 0 !important;

        .sharee-container__canvas {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .sharee-sharing-indicator {
        display: none;
      }
      .sharee-container__canvas-outline {
        border-color: transparent !important;
      }
    }
  }

  .banner-group {
    position: absolute;
    top: 0;
    left: 50%;

    .vertical-banner {
      //background-color: $red;
      color: white;
      position: absolute;

      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      &__text {
        writing-mode: vertical-lr;
        text-orientation: upright;
      }
    }

    .horizontal-banner {
      //background-color: $red;
      color: white;
      position: absolute;

      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  }

  // Popup menus for Audio options and More button
  .audio-option-menu__pop-menu,
  .more-button__pop-menu {
    top: 0;
    bottom: auto;
    left: 100% !important;
  }

  // Reaction Emote overlay
  .reaction-picker {
    transform: translate(0, 0);
    bottom: auto;
  }

  // Fullscreen icon in top right
  .meeting-info-container__full-screen {
    background: white;

    .full-screen-widget {
      background: white !important;

      &:hover {
        background: rgba(0, 0, 0, 0.1) !important;
      }

      svg path {
        stroke: black;
        stroke-opacity: 0.62;
      }
    }
  }

  // Speaker webcam window
  .ReactModal__Content[aria-label='suspension-window'] {
    position: fixed !important;
    inset: 0 auto auto 100px !important;

    .suspension-window-container {
      transform: translate(0, 0) !important;
    }
  }

  .menarini-logo {
    max-width: 90px;
    padding: 10px;
    margin-bottom: 80px;
    position: fixed;
    z-index: 999;
  }
}

// Layout for items
.zoom-auditorium {
  background-position: center top;
  background-image: url('../../assets/images/CC_Auditorium_v3.png');
  background-size: cover;

  // ------ Screen is cropped on the bottom
  // width is fixed, so items are positioned in vw

  min-height: 36vw;
  min-width: unset;

  #root {
    min-height: unset;
    max-width: unset;
  }

  // Layout of the 'Join meeting' screen
  .main-content {
    .container-preview {
      position: absolute;
      top: 4vw !important; // vertical banner top
      left: -17.209375vw; // horizontal banner left
      height: 23.21875vw !important; // 30.9375 (vertical banner height) - 6.71875 ( horizontal banner height) - 1 (gutter)
      width: 45.8203125vw !important; // horizontal banner width

      .av-preview-container {
        height: calc(23.21875vw - 46px) !important;
        width: 45.8203125vw !important;
      }

      .mini-layout-body {
        margin-top: 0.5vw !important;
      }
    }
  }

  //-- Main body of the zoom call video area
  .video-share-layout {
    position: relative;
    top: 4vw !important; // vertical banner top
    left: -17.209375vw; // horizontal banner left
    height: 23.21875vw !important; // 30.9375 (vertical banner height) - 6.71875 ( horizontal banner height) - 1 (gutter)
    width: 45.8203125vw !important; // horizontal banner width

    .SplitPane {
      .Pane1 {
        max-height: 100%;
      }
    }
  }

  //-- Layout when screen not being shared
  .main-layout {
    .single-main-container__main-view {
      .single-main-container__canvas {
        height: 23.21875vw;
        width: 45.8203125vw;
      }

      .single-main-container__video-frame {
        height: 23.21875vw !important;
        width: 45.8203125vw !important;
      }
    }
  }

  .banner-group {
    .vertical-banner {
      background-image: url("../../assets/images/CCV_Banner_v1.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 30.9375vw; // 792 (banner height) / 2560 (image width) vw
      width: 10.390625vw; // 266 (banner width) / 2560 (image width) vw
      top: 4vw; // Other elements are positioned relative to this measurement
      left: -28.6vw; // Other elements are positioned relative to this measurement

      &__text {
        font-size: 28pt;
      }
    }

    .horizontal-banner {
      background-image: url("../../assets/images/CCV_Banner_v2.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 6.71875vw; // 172 (banner height) / 2560 (image width)
      width: 45.8203125vw; // 1173 (banner height) / 2560 (image width)
      top: 28.21875vw; // 4 (vertical banner top) + 30.9375 (vertical banner height) - 6.71875 (banner height)
      left: -17.209375vw; // -28.6 (vertical banner left) + 10.390625 (vertical banner width) + 1 (gutter)

      &__text {
        font-size: 28pt;
      }
    }
  }

  // ------ Screen is cropped on left and right

  @media screen and (max-aspect-ratio: 2560/1224) {

    // Layout of the 'Join meeting' screen
    .main-content {
      .container-preview {
        position: absolute;
        top: 8.366013071895424836601307189542vh !important; // vertical banner top
        left: -35.993464052287581699346405228758vh; // horizontal banner left
        height: 48.562091503267973856209150326797vh !important; // 64.70... (vertical banner height) - 14.05... ( horizontal banner height) - 1vw @ 2560 (gutter)
        width: 95.833333333333333333333333333333vh !important; // horizontal banner width

        .av-preview-container {
          height: calc(48.562091503267973856209150326797vh - 46px) !important;
          width: 95.833333333333333333333333333333vh !important;
        }

        .mini-layout-body {
          margin-top: 0.5vh !important;
        }
      }
    }

    //-- Main body of the zoom call video area
    .video-share-layout {
      top: 8.366013071895424836601307189542vh !important; // vertical banner top
      left: -35.993464052287581699346405228758vh; // horizontal banner left
      height: 48.562091503267973856209150326797vh !important; // 64.70... (vertical banner height) - 14.05... ( horizontal banner height) - 1vw @ 2560 (gutter)
      width: 95.833333333333333333333333333333vh !important; // horizontal banner width

      .SplitPane {
        .Pane1 {
          max-height: 100%;
        }
      }
    }

    //-- Layout when screen not being shared
    .main-layout {
      .single-main-container__main-view {
        .single-main-container__canvas {
          // Value of h/w should be min of the one set in .video-share-layout
          height: 48.562091503267973856209150326797vh;
          width: 95.833333333333333333333333333333vh;
        }

        .single-main-container__video-frame {
          height: 48.562091503267973856209150326797vh !important;
          width: 95.833333333333333333333333333333vh !important;
        }
      }
    }

    .banner-group {
      .vertical-banner {
        height: 64.705882352941176470588235294118vh; // 792 (banner height)/ 1224 (image height)
        width: 21.732026143790849673202614379085vh; // 266 (banner width) / 1224 (image width)
        top: 8.366013071895424836601307189542vh; // 4vw @ 2560 (banner top in vw layout) / 1224 (image height)
        left: -59.816993464052287581699346405229vh; // -28.6vw @ 2560 (banner left in vw layout) / 1224 (image height)
        &__text {
          font-size: 25pt;
        }
      }

      .horizontal-banner {
        height: 14.052287581699346405228758169935vh; // 172(banner height) / 1224(image width)
        width: 95.833333333333333333333333333333vh; // 1173(banner height) / 1224(image width)
        top: 59.019607843137254901960784313725vh; // 8.36... (vertical banner top) + 64.70... (vertical banner height) - 14.05... (banner height)
        left: -35.993464052287581699346405228758vh; // -59.81... (vertical banner left) + 21.73... (vertical banner width) + 1vw @ 2560 (gutter)

        &__text {
          font-size: 25pt;
        }
      }
    }
  }
}
