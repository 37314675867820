$t: 0.2s;
$delay: 0.05s;

.side-nav {
  width: 300px;
  background-color: $white;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 25px;
  overflow-y: scroll;
  position: fixed;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

  &__unauthorized {
    height: 100%;
    img{
      max-width: 100%;
    }
    @include all-mobile-devices{
      img{
        max-width: 80%;
      }
    }
  }

  &__authorized {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__logo {
    padding: 0px 0px 25px;
    height: 80px
  }

  &__menu-container {
    position: relative;
    padding-bottom: 200px;
    height: 75%;
  }

  &__menu {
    display: flex;
    height: 60px;
    align-items: center;
    text-decoration: none;
    margin: 10px 0px;
    border-radius: 10px;
    padding: 0px 25px;
    color: $black;
    width: 250px;

    &.active {
      display: flex;
      flex-wrap: wrap;
      background-color: $red;
      color: $white;
    }
    &.active:hover{
      color: $white !important;
    }

    &--sub {
      width: 100%;
      text-decoration: none;
      padding-left: 35px;
      color: $black;
      height: 35px;

      &:link,
      &:visited {
        text-decoration: none;
        color: $black;
        font-size: 1.3rem;
        //font-weight: 500;
        letter-spacing: 0.55px;
        line-height: 14px;
      }

      &.active {
        color: $red;
        background-color: unset !important;
      }
    }

    &--user {
      padding: 0px 5px;
      max-width: 100%;
      word-break: break-all;
    }
  }

  &__menu-text {
    padding-left: 20px;
  }

  &__sub-item {
    display: flex;
  }

  &__user-info {
    position: fixed;
    width: 250px;
    bottom: 0px;
    padding: 25px 0px;
    background-color: white;
  }

  &__user-info-menu {
    background-color: $red;
  }

  &__user-icon {
    padding-top: 5px;
  }

  &__user-detail-container {
    display: flex;
    align-items: center;
  }

  &__user-detail {
    padding-left: 5px;
  }

  &__user-detail-name {
    font-weight: 600;
    line-height: 28px;
    text-transform: capitalize;
  }

  &__user-detail-email {
    line-height: 28px;
    font-size: 14px;
  }

  &__badge {
    margin-left: 10px;
    background-color: $green-dark;
  }
}

.side-nav::-webkit-scrollbar {
  display: none;
}
