.tool-tip-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a{
    text-decoration: none;
  }
  &__button {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    min-width: 118px;
    height: 50px !important;
    color: black !important;
    margin-right: 15px !important;

    &--no-min-width {
      min-width: auto;
    }

    .MuiIconButton-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__icon {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    color: black !important;
    width: 45px;
    height: 45px;
    .MuiSvgIcon-root {
      font-size: 30px !important;
    }
  }
}

.table-paper {
  padding: 10px;
}

.table-container {
  padding-left: 20px;
  padding-right: 20px;
  .MuiTableBody-root {
    .MuiTableCell-root {
      border: none !important;
    }
  }

  tbody {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background: #ff000026;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $red;
  }
}
