.graph-header {
  position: relative;
  padding: 20px 25px 10px;

  &__header {
    flex: 1 1 100%;
  }

  &__title {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
  }
  &__category {
    color: $red;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
  }
  p {
    text-align: left;
  }

  &__actions {
    flex: 1 0;
  }
}
