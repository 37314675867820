*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: unset !important;
  min-width: unset !important;
}

body {
  box-sizing: border-box;
  background-color: white;
  color: black;
  max-height: 100%;
  max-width: 100vw;
}

#root {
  width: 100%;
  height: 100%;
  max-width: 100vw;

  .unity{
    width: 100%;
    height: 100%;
    max-width: 100vw;
    #unity-container{
      width: 100%;
      height: 100%;
    }
  }
}

// text fields rewrite CSS
.MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: black !important;
  }
}
.MuiFormLabel-root {
  color: black !important;
}

.dialog-actions {
  padding-bottom: 16px !important;
}

.pad-bt-20{
  padding-bottom: 20px;
}
.pad-right-20{
  padding-right: 20px;
}

// we have to hide this otherwise it shows on top of all pages/routes
#zmmtg-root {
  display: none;
}

// overwrite bootstrap css from Zoom
p{
  margin: 0!important;
}

a:hover{
  text-decoration: none !important;
  color: inherit !important;
}

a:focus{
  text-decoration: none !important;
  color: inherit !important;
}
