.user-activity-log {
  &__header {
    margin: 10px 0px;
  }

  &__card {
    width: 100%;
    margin: 5px !important;
    border: 1px solid #dee6eb !important;
    padding: 10px 15px;
    border-radius: 5px;
  }

  &__user-info {
    display: flex;
    align-items: center;
  }

  &__user-name {
    font-weight: 600;
    line-height: 1.5rem;
  }

  &__detail p {
    line-height: 1rem;
  }
}

.activity-log {
  &__table {
    width: 100%;
    margin-bottom: 20px;
    &__row {
      margin: 0 !important;
      margin-top: 10px !important;
      max-width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      &--item {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $red;
        max-width: 100%;
        // do not remove the these 3 lines
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        p {
          font-weight: bold !important;
          font-size: 15px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: white;
        }
      }

      &--log-desc {
        max-width: 63% !important;
        border: 1px solid #dee6eb !important;
        border-radius: 5px !important;
        padding-left: 20px !important;
        p {
          font-size: 12px !important;
          width: 100%;
        }
      }
    }
  }
}

.activity-log-container {
  width: 100%;
}
