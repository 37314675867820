@import '../abstracts/mixins';

.set-password {
  padding: 0px 10%;
  @include all-mobile-devices{
    padding-left: 0;
    width: 100vw !important;
    max-width: 95%!important;
    padding-right: 16px !important;
  }
  &__title {
    padding-bottom: 50px;
    text-align: center;
    @include all-mobile-devices{
      font-size: 36px;
    }
    @include ipad-pro-portrait-and-landscape {
      max-width: 68%;
    }
    @include tablet-ipad {
      max-width: 68%;
    }
  }
  &__success {
    text-align: center;
  }

  &__input {
    width: 350px;
    margin: 10px 0px !important;
  }

  &__button {
    width: 250px;
    margin-top: 30px;
  }
}
