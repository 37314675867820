.pie-chart {
  border-radius: 10px;
  border: 1px solid #dee6eb;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: row;

  &__labels{
    height: 250px;
  }
}
