@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/Myriad Pro Bold.otf') format('otf');
}

html,
body.body {
  font-family: 'PT Sans', sans-serif;
  color: $primary-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

h1 {
  font-size: 64px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: normal;

  strong {
    font-weight: 900;
  }
}

p {
  font-size: 16px;
  line-height: 28px;
}
