.zoom-meeting {
  background-color: transparent;
}

.zoom-overlay {
  & #meeting-sdk-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }

  & div[role='dialog'] {
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }
}

#meeting-sdk-element {
  //min-height: 100vh;
  //height: 100%;
  //width: 100%;
  //min-width: 100vw;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;

  //.react-resizable{
  //  width: 100%;
  //  height: 100%;
  //}
}
#transition-popper {
  //z-index: 10;
}

.zoom-thanks{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  &__message{
    max-width: 350px;
    text-align: center;
    margin: 15px;
    h1{
      font-family: 'Myriad Pro Bold' !important;
      font-size: 30px;
      line-height: 40px;
      font-weight: bold;
      color: #000000;
    }
    p{
      font-size: 20px;
      line-height: 35px;
      font-weight: bold;
      color: #000000;
    }
  }

  img{
    margin-top: 20px;
    max-width: 260px;
    margin-bottom: 40px;
  }

  iframe {
    border: none;
  }
}
