.unity {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  @include all-mobile-devices{
    overflow: hidden !important;
  }
  .message{
    max-width: 263px;
    text-align: center;
    margin-top: 15px;
    h1{
      font-family: 'Myriad Pro Bold' !important;
      font-size: 30px;
      line-height: 40px;
      font-weight: bold;
      color: #000000;
    }
  }
  #unity-canvas {
    width: 100%;
    height: 100%
  }

  #unity-container{
    max-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 500px){
      @supports not (-webkit-touch-callout: none) {
        /* CSS for other than iOS devices */
        justify-content: flex-start;
      }
    }

    .mobile-fix{
      @media screen and (max-width: 500px){
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          overflow: hidden;
        }

        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          overflow: hidden;
        }
      }
    }

    #unity-logo{
      width: 263px;
      //height: 263px;
      height: 160px;
      margin: 0 auto;
    }

    .progress{
      text-align: center;
    }
  }
  iframe{
    //height: 100vh !important;
    width: 100vw;
    border: none;
    max-width: 100%;
    max-height: 99%;
  }
}

.iframe {
  height: 100vh !important;
  width: 100vw !important;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  iframe{
    height: 100vh !important;
    width: 100vw;
    border: none;
    max-width: 100%;
    max-height: 100%;
  }
}

.mobile-fix{
  @media screen and (max-width: 500px){
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      overflow: hidden;
    }

    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      overflow: hidden;
    }
  }
}

.embed{
  background: black;
}

.webviewContainer{
  body{
    overflow: hidden!important;
  }
}

//#webview_WebViewObject{
//  top: 12% !important;
//  height: 80% !important;
//}
